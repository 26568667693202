import React, { useState, useRef, useEffect } from "react";
import { injectIntl, IntlShape } from "react-intl";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Helmet } from "react-helmet";
import LoadingBar from "react-top-loading-bar";
import AuthenticationContainer from "../container";
import Block from "../../../components/Tailwind/Block";
import GroundFormik from "../../../components/Tailwind/Form";
import { Value } from "../../../utils/types";
import history from "../../../history";
import { EnumPaths } from "../../../utils/navigation";
import images from "../../../images";
import Button from "../../../components/Tailwind/Button";
import LinkButton from "../../../components/Tailwind/LinkButton";
import IntlMessages from "../../../utils/messages";
import { GroundAuthContextStore } from "../../../lib/ground-aws-cognito-auth-core";
import LocaleSwitch from "../../../containers/LocaleSwitch";

interface Props {
  intl: IntlShape;
}

const ForgotPassword = (props: Props) => {
  const { intl } = props;
  const ref = useRef(null);

  const schema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email(intl.formatMessage({ id: "general.field.email.invalid" })),
  });

  const forgotPassword = GroundAuthContextStore.useStoreActions(
    actions => actions.authentication.forgotPassword
  );
  const forgotPasswordSuccess = GroundAuthContextStore.useStoreState(
    actions => actions.authentication.forgotPasswordSuccess
  );
  const [loading, setLoading] = useState(false);

  const values: Value[] = [
    {
      id: "email",
      name: "email",
      type: "email",
      label: "general.email.address",
      placeholder: "general.email.address.placeholder",
      required: true,
      value: "",
      useIntl: true,
    },
  ];

  useEffect(() => {}, [forgotPasswordSuccess]);

  const handleSubmit = payload => {
    setLoading(true);
    ref.current!.continuousStart();

    forgotPassword({
      email: payload.email.trim(),
    })
      .then(() => {
        setLoading(false);
        ref.current?.complete();

        toast(intl.formatMessage({ id: "page.forgot.password.email.sent" }), {
          type: "success",
        });
      })
      .catch(err => {
        setLoading(false);
        ref.current?.complete();

        const { data } = err.response;
        const code = data?.code || data?.error_code;
        // Security with check of the error code
        if (code === "UserNotFoundException") {
          toast(
            intl.formatMessage({
              id: `error.forgot.${code}`,
            }),
            { type: "success" }
          );
        } else if (intl.messages[`error.auth.${code}`]) {
          toast(
            intl.formatMessage({
              id: `error.auth.${code}`,
            }),
            { type: "success" }
          );
        } else {
          toast(
            intl.formatMessage({
              id: "page.error.default.message",
            }),
            {
              type: "error",
            }
          );
        }
      });
  };

  return (
    <AuthenticationContainer>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: "page.forgot.password.document.title",
          })}
        </title>
      </Helmet>
      <LoadingBar height={5} color="#00D480" ref={ref} />
      <div className="flex justify-end pt-4 pr-4">
        <LocaleSwitch />
      </div>
      <div className="flex relative flex-col items-center h-screen justify-center">
        <div className="flex justify-center w-full sm:w-3/4 lg:w-5/12 cursor-pointer">
          <img
            alt=""
            src={images.ground}
            className="w-12 h-12"
            onClick={() => history.push(`/${EnumPaths.SIGNIN}`)}
          />
        </div>
        <div className="flex flex-col">
          <p className="pt-3 m-5 text-30px text-ground-black-100 text-center leading-5 font-semibold">
            <IntlMessages id="general.forgot.password" />
          </p>
          <p className="pb-3 text-14px text-ground-gray-100 text-center leading-6">
            <IntlMessages id="page.forgot.password.subtitle" />
          </p>
        </div>
        <div className="w-full sm:w-3/4 lg:max-w-screen-sm">
          <Block>
            <div className="py-3 sm:py-5 flex" />
            <GroundFormik
              item={null}
              values={values}
              onSubmit={handleSubmit}
              alternate={false}
              validationSchema={schema}
              width=""
              className="lg:px-10"
            >
              <div
                key="forgotpassword"
                className="flex flex-col pb-10 pt-5 px-6 lg:px-10 items-center"
              >
                <Button
                  id="btn-forgot-password"
                  name="btn-forgot-password"
                  item={null}
                  type="submit"
                  disabled={loading}
                  className="w-full"
                >
                  <span className="text-center">
                    <IntlMessages id="general.submit" />
                  </span>
                </Button>
                <div className="mt-4">
                  <LinkButton
                    id="link-back-forgot-password"
                    name="link-back-forgot-password"
                    label="general.cancel"
                    disabled={loading}
                    onClick={() => {
                      history.push(`/${EnumPaths.SIGNIN}`);
                    }}
                  />
                </div>
              </div>
            </GroundFormik>
          </Block>
        </div>
      </div>
    </AuthenticationContainer>
  );
};

export default injectIntl(ForgotPassword);
