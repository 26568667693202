import React, { useState } from "react";
import { injectIntl, IntlShape } from "react-intl";
import { match as Match } from "react-router-dom";
import { toast } from "react-toastify";
import Table from "../../../../../components/Table";
import { TableChangeParams } from "../../../../../components/Table/types";
import Button from "../../../../../components/Tailwind/Button";
import Dropdown, {
  DropdownValues,
} from "../../../../../components/Tailwind/Dropdown";
import history from "../../../../../history";
import { GroundGraphqlContextStore } from "../../../../../lib/ground-aws-graphql-core";
import { EnumPermissionEntity } from "../../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { Order } from "../../../../../lib/ground-aws-graphql-core/models/Order";
import { Unavailability } from "../../../../../lib/ground-aws-graphql-core/models/Unavailability";
import IntlMessages from "../../../../../utils/messages";
import ConfirmModal from "../../../../../utils/modal/confirm";
import {
  EnumBookingFrom,
  EnumPaths,
  getSpecificIdFromUrl,
  navTo,
} from "../../../../../utils/navigation";
import { getImageUrl } from "../../../../../utils/picture";
import { getTranslation } from "../../../../../utils/translation";
import { retrieveUserFromOrder } from "../../../../../utils/user";
import images from "../../../../../images";
import { getDataRelativeToOrderItem } from "../../../../../components/Tailwind/GridOrderItems/GridOrderItem";
import { Center } from "../../../../../lib/ground-aws-graphql-core/models/Center";

type Props = {
  center: Center;
  bookings: [Unavailability] | null;
  date: Date;
  total: number | null | undefined;
  onChange: (params: TableChangeParams) => void;
  setRefresh: (refresh: boolean) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  limit: number;
  intl: IntlShape;
  match: Match<{ cid: string }>;
};

const BookingsTable = (props: Props) => {
  const {
    center,
    bookings,
    date,
    total,
    onChange,
    setRefresh,
    loading,
    setLoading,
    limit,
    intl,
    match,
  } = props;

  const [orderToCancel, setOrderToCancel] = useState<Order | null>(null);
  const [clientName, setClientName] = useState<string | null | undefined>(null);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const cancelOrder = GroundGraphqlContextStore.useStoreActions(
    actions => actions.cancellation.cancelOrder
  );

  const handleCancelOrder = () => {
    if (orderToCancel?.globalOrder) {
      setLoading(true);
      setShowCancelModal(false);
      cancelOrder({
        globalOrderId: orderToCancel.globalOrder.id,
        orderId: orderToCancel.id,
      })
        .then(() => {
          setRefresh(true);
          toast(
            intl.formatMessage({
              id: "page.order.cancel.order.success",
            }),
            { type: "success" }
          );
        })
        .catch(() => {
          toast(
            intl.formatMessage({
              id: "page.order.cancel.order.error",
            }),
            {
              type: "error",
            }
          );
        })
        .finally(() => setLoading(false));
    }
  };

  const bookingsTableHead = [
    "general.date",
    "page.list.bookings.table.head.client",
    "page.list.bookings.table.head.participants",
    "page.list.bookings.table.head.name",
    "general.reason",
    "general.actions",
  ];

  const bookingsTableBody = (center: Center) =>
    bookings?.map(el => {
      const { product, orderItem, user } = el;

      const { booking } = getDataRelativeToOrderItem(center, {
        ...orderItem,
        unavailability: el,
      });

      const pictures = product?.pictures;
      const picture = pictures && pictures.length > 0 ? pictures[0] : null;

      const order = orderItem?.order;
      const channel = orderItem?.order.channel;

      const options = order?.orderItems?.items?.filter(
        i => i.id !== orderItem.id && i.relatedOrderItemId === orderItem.id
      );

      const { client } = retrieveUserFromOrder(
        user,
        orderItem?.order.metadata,
        channel
      );

      const handleClick = () => {
        history.push({
          pathname: `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${match.params.cid}/${EnumPaths.GLOBAL_ORDERS}/${order?.globalOrder?.id}`,
          state: { from: EnumBookingFrom.BOOKING_LIST },
        });
      };

      const dropdownValues: DropdownValues[] = [
        {
          id: "booking_order",
          label: "page.list.bookings.show.order",
          icon: images.details,
          onClick: handleClick,
        },
        {
          id: "cancel_booking",
          label: "general.cancel",
          icon: images.cancel,
          onClick: () => {
            setClientName(client);
            setOrderToCancel(order);
            setShowCancelModal(!showCancelModal);
          },
        },
      ];

      return {
        rowElements: [
          {
            element: (
              <div className="flex flex-col items-start">
                {booking && !booking.sameDay && (
                  <>
                    <span className="text-ground-gray-100">
                      <IntlMessages id="general.from" /> {booking.startDay}{" "}
                      <IntlMessages id="general.at" /> {booking.startHour}
                    </span>
                    <span className="text-ground-gray-100">
                      <IntlMessages id="general.to" /> {booking.endDay}{" "}
                      <IntlMessages id="general.at" /> {booking.endHour}
                    </span>
                  </>
                )}
                {booking && booking.sameDay && (
                  <div className="flex text-ground-gray-100">
                    {booking.startDay}
                    {" - "}
                    {booking.startHour}
                    {" > "}
                    {booking.endHour}
                  </div>
                )}
              </div>
            ),
            onCellClick: handleClick,
          },
          { element: client, onCellClick: handleClick },
          { element: orderItem.quantity, onCellClick: handleClick },
          {
            element: (
              <div className="flex items-center">
                <div className="flex mr-3 sm:block">
                  {picture && (
                    <img
                      alt=""
                      src={getImageUrl(
                        EnumPermissionEntity.USER,
                        picture,
                        50,
                        50
                      )}
                      className="h-8 w-8 rounded-3"
                    />
                  )}
                </div>
                <div className="flex flex-col">
                  <span>{getTranslation(el?.product?.name)}</span>
                  {options && options.length > 0 && (
                    <span className="text-xs text-ground-blue-100 lowercase">
                      {options.length}{" "}
                      {intl.formatMessage({ id: "general.options" })} :
                      {options.map((option, index) => {
                        let label = getTranslation(option.name);
                        if (option.unitQuantity && option.unitQuantity > 1) {
                          label = `${label} (x${option.unitQuantity})`;
                        }

                        return (
                          <span className="text-ground-gray-100" key={index}>
                            {index !== 0 && ", "}
                            {label}
                          </span>
                        );
                      })}
                    </span>
                  )}
                </div>
              </div>
            ),
            onCellClick: handleClick,
          },
          {
            element: (
              <span className="text-ground-black-100">
                {order?.reason &&
                  intl.formatMessage({
                    id: `page.list.bookings.reason.${order?.reason}`,
                  })}
              </span>
            ),
            onCellClick: handleClick,
          },
          {
            element: <Dropdown values={dropdownValues} />,
          },
        ],
      };
    });

  const handleBook = () => {
    const centerId = getSpecificIdFromUrl(
      history.location.pathname,
      "centers/"
    );

    if (centerId) {
      history.push(
        navTo(`${EnumPaths.BOOKINGS}/${EnumPaths.BOOK_AVAILABILITY}`, centerId),
        { date }
      );
    }
  };

  return (
    <>
      <div className="flex pt-4 justify-end">
        <Button
          id="btn-book"
          name="btn-book"
          item={null}
          outline
          type="button"
          onClick={handleBook}
        >
          <IntlMessages id="page.list.bookings.book.space" />
        </Button>
      </div>
      <Table
        initialParams={{
          pageIndex: 0,
          type: "",
          filters: [{ type: "START", value: date.toISOString() }],
          limit,
        }}
        permissionEntity={EnumPermissionEntity.BOOKING_LIST}
        head={bookingsTableHead}
        body={bookingsTableBody(center)}
        noDataText="page.list.bookings.empty"
        paginationTotal={total}
        onChange={onChange}
        loading={loading}
        setLoading={setLoading}
      />

      <ConfirmModal
        isOpen={showCancelModal}
        toggle={() => setShowCancelModal(!showCancelModal)}
        onRequestClose={() => setShowCancelModal(!showCancelModal)}
        handleConfirm={handleCancelOrder}
        content={
          <IntlMessages
            id="page.list.bookings.cancel.booking"
            values={{ name: clientName }}
          />
        }
      />
    </>
  );
};

export default injectIntl(BookingsTable);
