import React, { useState, useRef, useEffect } from "react";
import { injectIntl, IntlShape } from "react-intl";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import * as Yup from "yup";
import LoadingBar from "react-top-loading-bar";
import AuthenticationContainer from "../container";
import Block from "../../../components/Tailwind/Block";
import GroundFormik from "../../../components/Tailwind/Form";
import { Value } from "../../../utils/types";
import history from "../../../history";
import { EnumPaths } from "../../../utils/navigation";
import images from "../../../images";
import Button from "../../../components/Tailwind/Button";
import contextStore from "../../../redux/store";
import IntlMessages from "../../../utils/messages";
import { GroundAuthContextStore } from "../../../lib/ground-aws-cognito-auth-core";
import LocaleSwitch from "../../../containers/LocaleSwitch";

interface Props {
  intl: IntlShape;
}

const SignIn = ({ intl }: Props) => {
  const ref = useRef(null);

  const schema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email(intl.formatMessage({ id: "general.field.email.invalid" })),
  });

  const [loading, setLoading] = useState(false);
  const signIn = GroundAuthContextStore.useStoreActions(
    actions => actions.authentication.signIn
  );

  const setTokens = contextStore.useStoreActions(
    actions => actions.authentication.setTokens
  );

  const me = GroundAuthContextStore.useStoreState(
    state => state.authentication.me
  );

  const values: Value[] = [
    {
      id: "email",
      name: "email",
      type: "email",
      label: "general.email.address",
      placeholder: "general.email.address.placeholder",
      required: true,
      disabled: loading,
      value: "",
      useIntl: true,
    },
    {
      id: "password",
      name: "password",
      type: "password",
      label: "page.signin.password",
      placeholder: "general.password.placeholder",
      required: true,
      disabled: loading,
      value: "",
      useIntl: true,
    },
  ];

  useEffect(() => {
    if (me && process.env.REACT_APP_GOOGLE_ANALYTICS) {
      // google anaytics set user_role
      window.gtag("config", process.env.REACT_APP_GOOGLE_ANALYTICS, {
        user_id: me.id,
      });
      window.gtag("set", "user_properties", {
        user_role: me.role,
      });
    }
  }, [me]);

  const handleSubmit = formValues => {
    setLoading(true);
    ref.current!.continuousStart();

    signIn({
      email: formValues.email.trim(),
      password: formValues.password.trim(),
    })
      .then(response => {
        const { data } = response;
        const code = data?.code || data?.error_code;
        if (code === "NewPasswordRequired") {
          history.push(`${EnumPaths.CHANGE_PASSWORD}`, {
            email: formValues.email,
            temporaryPassword: formValues.password,
          });
        } else {
          setTokens(response.data);
          setLoading(false);
          ref.current?.complete();
          history.push(`/${EnumPaths.ROOT}`);
        }
      })
      .catch(err => {
        setLoading(false);

        ref.current?.complete();
        const { data } = err.response;
        const code = data?.code || data?.error_code;

        const message = intl.formatMessage({ id: `error.auth.${code}` });
        toast(message, {
          type: "error",
        });
      });
  };

  return (
    <AuthenticationContainer>
      <Helmet>
        <title>
          {intl.formatMessage({ id: "page.signin.document.title" })}
        </title>
      </Helmet>
      <LoadingBar height={5} color="#00D480" ref={ref} />
      <div className="flex justify-end pt-4 pr-4">
        <LocaleSwitch />
      </div>
      <div className="flex relative flex-col items-center h-screen justify-center">
        <img alt="" src={images.ground} className="w-12 h-12" />
        <div className="flex flex-col">
          <p className="pt-3 m-5 text-30px text-ground-black-100 text-center leading-5 font-semibold">
            <IntlMessages id="page.signin.title" />
          </p>
          <p className="pb-3 text-14px text-ground-gray-100 text-center leading-6">
            <IntlMessages id="page.signin.subtitle" />
          </p>
        </div>

        <div className="w-full sm:w-3/4 lg:max-w-screen-sm">
          <Block>
            <div className="py-3 sm:py-5 flex" />
            <GroundFormik
              item={null}
              values={values}
              onSubmit={handleSubmit}
              alternate={false}
              validationSchema={schema}
              width=""
              className="lg:px-10"
            >
              <div
                key="login"
                className="flex flex-col py-10 px-6 items-center lg:px-10"
              >
                <Button
                  id="btn-signin"
                  name="btn-signin"
                  type="submit"
                  item={null}
                  disabled={loading}
                  className="w-full"
                >
                  <span className="text-center">
                    <IntlMessages id="page.signin.signin" />
                  </span>
                </Button>
                <h2
                  id="link-forgot-password"
                  data-cy="link-forgot-password"
                  onClick={() => history.push(`/${EnumPaths.FORGOT_PASSWORD}`)}
                  className="pt-4 text-13px text-ground-blue-100 cursor-pointer"
                >
                  <IntlMessages id="page.signin.forgot.password" />
                </h2>
              </div>
            </GroundFormik>
          </Block>
        </div>
      </div>
    </AuthenticationContainer>
  );
};

export default injectIntl(SignIn);
