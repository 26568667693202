import React, { useEffect, useState } from "react";
import { IntlShape } from "react-intl";
import { FieldAttributes } from "formik";
import { match as Match } from "react-router-dom";
import Block from "../../../../components/Tailwind/Block";
import Header from "../../../../components/Tailwind/Block/Header";
import { EnumPermissionEntity } from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import DefaultForm, {
  AdditionalFieldAttributes,
} from "../../../../components/Form";
import overrideClasses from "../../../../utils/overrideClasses";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import FormSwitch from "../../../../components/Form/FormSwitch";
import { toast } from "react-toastify";
import { EnumPaths } from "../../../../utils/navigation";
import history from "../../../../history";
import {
  getAttributeKeysTypeItems,
  getAttributeKeysTypologyItems,
  isString,
} from "../../../../utils/types";

interface Props {
  match?: Match<{ id: string }>;
  intl: IntlShape;
  edition: boolean;
}

const AttribteKeyForm = (props: Props): JSX.Element => {
  const { intl, match, edition } = props;
  const [enabled, setEnabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [togglePanel, setTogglePanel] = useState(false);

  const getAttributeKey = GroundGraphqlContextStore.useStoreActions(
    actions => actions.attributeKeys.getAttributeKey
  );

  const createAttributeKey = GroundGraphqlContextStore.useStoreActions(
    actions => actions.attributeKeys.createAttributeKey
  );

  const updateAttributeKey = GroundGraphqlContextStore.useStoreActions(
    actions => actions.attributeKeys.updateAttributeKey
  );

  const setAttributeKey = GroundGraphqlContextStore.useStoreActions(
    actions => actions.attributeKeys.setAttributeKey
  );

  const attributeKey = GroundGraphqlContextStore.useStoreState(
    state => state.attributeKeys.attributeKey
  );

  const formFields: FieldAttributes<AdditionalFieldAttributes>[] = [
    {
      name: "code",
      label: "page.attr.key.code",
      placeholder: "page.attr.key.code.placeholder",
      initialValue: attributeKey?.code,
      required: true,
    },
    {
      name: "label",
      label: "page.attr.key.label",
      placeholder: "page.attr.key.label.placeholder",
      initialValue: attributeKey?.label,
      required: true,
      translatable: true,
    },
    {
      name: "type",
      label: "page.attr.key.type",
      initialValue: attributeKey?.type,
      required: true,
      disabled: edition,
      as: "select",
      options: getAttributeKeysTypeItems(),
    },
    {
      name: "typology",
      label: "page.attr.key.typology",
      initialValue: attributeKey?.typology,
      required: true,
      disabled: edition,
      as: "select",
      options: getAttributeKeysTypologyItems(),
    },
    {
      name: "required",
      label: "page.attr.key.required",
      initialValue: attributeKey ? attributeKey?.required : false,
      component: props => (
        <FormSwitch
          {...props}
          description="page.attr.key.required.description"
        />
      ),
    },
    {
      name: "editable",
      label: "page.attr.key.editable",
      initialValue: attributeKey ? attributeKey?.editable : true,
      component: props => (
        <FormSwitch
          {...props}
          description="page.attr.key.editable.description"
        />
      ),
    },
  ];

  const fetchData = () => {
    setLoading(true);
    if (match?.params.id) {
      getAttributeKey({ id: match?.params.id }).finally(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();

    // Clean the event from the state when we unmount this component
    return () => {
      setAttributeKey(null);
    };
  }, []);

  useEffect(() => {
    if (attributeKey) setEnabled(attributeKey.enabled);
  }, [attributeKey]);

  const handleShowSavePanel = () => {
    setTogglePanel(true);
  };

  const handleSubmit = (values: any) => {
    setLoading(true);
    const { label, code, editable, required, type, typology } = values;
    if (edition && attributeKey) {
      updateAttributeKey({
        code,
        editable,
        required,
        label: isString(label) ? JSON.parse(label) : label,
        id: attributeKey.id,
        enabled: enabled,
      })
        .then(() => {
          history.push(`/${EnumPaths.ROOT}/${EnumPaths.ATTRIBUTE_KEYS}`);
          toast(
            intl.formatMessage({
              id: "page.attr.key.update.success",
            }),
            { type: "success" }
          );
        })
        .catch(() => {
          history.push(`/${EnumPaths.ROOT}/${EnumPaths.ATTRIBUTE_KEYS}`);
          toast(
            intl.formatMessage({
              id: "page.attr.key.update.error",
            }),
            {
              type: "error",
            }
          );
        });
    } else {
      createAttributeKey({
        code,
        required,
        editable,
        type,
        typology,
        label: isString(label) ? JSON.parse(label) : label,
        enabled,
      })
        .then(() => {
          history.push(`/${EnumPaths.ROOT}/${EnumPaths.ATTRIBUTE_KEYS}`);
          toast(intl.formatMessage({ id: "page.attr.key.create.success" }), {
            type: "success",
          });
        })
        .catch(() => {
          history.push(`/${EnumPaths.ROOT}/${EnumPaths.ATTRIBUTE_KEYS}`);
          toast(intl.formatMessage({ id: "page.attr.key.create.error" }), {
            type: "error",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
    setLoading(false);
  };

  return loading ? (
    <div className="loading" />
  ) : (
    <>
      {/**
       * When we were loading the form was initialized again and we would lose all data
       * Show loading here in order to not lose the fields data after a loading
       */}
      <div className={overrideClasses({ loading })} />

      {/* Hide when loading */}
      <div className={overrideClasses("px-8 pb-32", { hidden: loading })}>
        <Block>
          <Header
            item={attributeKey}
            checked={enabled}
            title={
              edition
                ? "page.list.attr.keys.update.attr.key"
                : "page.list.attr.keys.create.attr.key"
            }
            entity={EnumPermissionEntity.ATTRIBUTE_KEY}
            onChange={e => {
              setEnabled(e);

              // show save panel
              handleShowSavePanel();
            }}
            className="border-b border-gray-200"
          />

          <DefaultForm
            fields={formFields}
            onSubmit={handleSubmit}
            showPanel={togglePanel}
          />
        </Block>
      </div>
    </>
  );
};

export default AttribteKeyForm;
