import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Location } from "history";
import { injectIntl, IntlShape } from "react-intl";
import { toast } from "react-toastify";
import { TableChangeParams } from "../../../components/Table/types";
import { GroundGraphqlContextStore } from "../../../lib/ground-aws-graphql-core";
import PageSubheader from "../../../components/PageSubheader";
import { EnumPaths } from "../../../utils/navigation";
import history from "../../../history";
import Table from "../../../components/Table";
import Dropdown, {
  DropdownValues,
} from "../../../components/Tailwind/Dropdown";
import images from "../../../images";
import ConfirmModal from "../../../utils/modal/confirm";
import IntlMessages from "../../../utils/messages";
import { AppClient } from "../../../lib/ground-aws-graphql-core/models/Api/AppClient";

interface Props {
  intl: IntlShape;
  location: Location<{
    pageIndex: number;
    limit: number;
    type: string;
  }>;
}

const DEFAULT_LIMIT = 20;

const ListAppClients = (props: Props): JSX.Element => {
  const { intl, location } = props;
  const [loading, setLoading] = useState(false);

  const [showDeleteAppClientModal, setShowDeleteAppClientModal] =
    useState(false);

  const [appClientId, setAppClientId] = useState<string>();
  const [initialParams, setInitialParams] = useState<TableChangeParams | null>(
    location?.state
  );

  const listAppClients = GroundGraphqlContextStore.useStoreActions(
    actions => actions.appClients.listAppClients
  );

  const appClients = GroundGraphqlContextStore.useStoreState(
    state => state.appClients.appClients.items
  );

  const total = GroundGraphqlContextStore.useStoreState(
    state => state.appClients.appClients.total
  );

  const setAppClient = GroundGraphqlContextStore.useStoreActions(
    actions => actions.appClients.setAppClient
  );

  const deleteAppClient = GroundGraphqlContextStore.useStoreActions(
    actions => actions.appClients.deleteAppClient
  );

  useEffect(() => {
    if (location?.state) {
      fetchData(location?.state?.pageIndex, true, location?.state?.limit);
    } else fetchData();
  }, []);

  const fetchData = (pageIndex = 0, loader = true, limit = DEFAULT_LIMIT) => {
    if (loader) setLoading(true);

    listAppClients({
      offset: pageIndex * limit,
      limit,
    }).finally(() => setLoading(false));
  };

  const addNewAppClient = () => {
    history.push(
      `/${EnumPaths.ROOT}/${EnumPaths.APP_CLIENTS}/${EnumPaths.CREATION_MODE}`
    );
  };

  const handleOnChange = (changeParams: TableChangeParams) => {
    const { pageIndex, limit } = changeParams;

    setInitialParams(changeParams);

    fetchData(pageIndex, false, limit);
  };

  const handleEditAppClient = (id: string) => {
    const editLink = `/${EnumPaths.ROOT}/${EnumPaths.APP_CLIENTS}/${id}/${EnumPaths.EDIT_MODE}`;
    setAppClient(null);
    history.push(editLink);
  };

  const handleDelete = () => {
    setLoading(true);
    setShowDeleteAppClientModal(false);
    deleteAppClient({ id: appClientId! })
      .then(() => {
        toast(
          intl.formatMessage({
            id: "page.app.client.delete.success",
          }),
          { type: "success" }
        );
      })
      .catch(() => {
        toast(
          intl.formatMessage({
            id: "page.app.client.delete.error",
          }),
          {
            type: "error",
          }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const tableHead = [
    "page.app.client.name",
    "page.app.client.client_id",
    "page.app.client.client_secret",
    "general.actions",
  ];

  const tableBody = appClients?.map((appClient: AppClient) => {
    const { id, name, client_id, client_secret } = appClient;
    const editLink = `/${EnumPaths.ROOT}/${EnumPaths.APP_CLIENTS}/${id}/${EnumPaths.EDIT_MODE}`;

    const dropdownValues: DropdownValues[] = [
      {
        id: "edit_api_key",
        label: "page.list.app.clients.edit",
        icon: images.edit,
        link: editLink,
      },
      {
        id: "delete",
        label: "general.delete",
        icon: images.deleteIcon,
        onClick: () => {
          setShowDeleteAppClientModal(!showDeleteAppClientModal);
          setAppClientId(id);
        },
      },
    ];

    const appClientElements = [
      {
        element: name,
        onCellClick: () => handleEditAppClient(id),
      },
      {
        element: client_id,
        onCellClick: () => handleEditAppClient(id),
      },
      {
        element: client_secret,
        onCellClick: () => handleEditAppClient(id),
      },
      {
        element: (
          <Dropdown values={dropdownValues} dataCy={`api-key-dropdown-${id}`} />
        ),
      },
    ];

    return {
      rowElements: appClientElements,
    };
  });

  return (
    <div>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: `page.list.app.clients.document.title`,
          })}
        </title>
      </Helmet>

      <PageSubheader
        title="page.list.app.clients.title"
        nbOfResults={total}
        buttonRightTitle="page.list.app.clients.create.app.client"
        buttonRightAction={addNewAppClient}
        buttonRightId="btn-create-api-key"
      />

      <Table
        initialParams={initialParams}
        head={tableHead}
        body={tableBody}
        noDataText="page.list.app.clients.empty"
        onChange={handleOnChange}
        paginationLimit={DEFAULT_LIMIT}
        paginationTotal={total}
        loading={loading}
        setLoading={setLoading}
        className="px-8"
      />

      <ConfirmModal
        isOpen={showDeleteAppClientModal}
        onRequestClose={() =>
          setShowDeleteAppClientModal(!showDeleteAppClientModal)
        }
        toggle={() => setShowDeleteAppClientModal(!showDeleteAppClientModal)}
        handleConfirm={handleDelete}
        content={<IntlMessages id="page.list.app.clients.delete" />}
      />
    </div>
  );
};

export default injectIntl(ListAppClients);
