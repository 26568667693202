import React, { useState, useEffect } from "react";
import { injectIntl, IntlShape } from "react-intl";
import { toast } from "react-toastify";
import { getTranslation } from "../../../../../utils/translation";
import Header from "../../../../../components/Tailwind/Block/Header";
import GroundFormik from "../../../../../components/Tailwind/Form";
import {
  canManageCategories,
  EnumAction,
  Item,
  Value,
} from "../../../../../utils/types";
import Footer from "../../../../../components/Tailwind/Block/Footer";
import ModalCreateUpdateCategory from "..";
import Button from "../../../../../components/Tailwind/Button";
import { getCypressTestId } from "../../../../../utils/config";
import IntlMessages from "../../../../../utils/messages";
import {
  CreateCategoryInput,
  EnumCategoryType,
  EnumPermissionEntity,
  UpdateCategoryInput,
} from "../../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { Category } from "../../../../../lib/ground-aws-graphql-core/models/Category";
import { GroundGraphqlContextStore } from "../../../../../lib/ground-aws-graphql-core";
import { GroundAuthContextStore } from "../../../../../lib/ground-aws-cognito-auth-core";

interface Props {
  type: EnumCategoryType;
  isOpen: boolean;
  parent: boolean;
  categories: Category[] | null;
  toggle: (action: EnumAction | undefined, category?: any) => void;
  category: Category | null;
  action: EnumAction | undefined;
  intl: IntlShape;
  centerId: string;
  /** Use existing categories provided in `categories` instead of creating one */
  existing?: boolean;
}

const CategoryForm = (props: Props) => {
  const {
    toggle,
    action,
    categories,
    parent,
    type,
    category,
    centerId,
    intl,
    existing,
  } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const creation = action === EnumAction.CREATE;
  const [enabled, setEnabled] = useState<boolean>(creation ? false : true);

  const [parentAction, setParentAction] = useState<EnumAction>();

  const [modalCategoryOpen, setModalCategoryOpen] = useState(false);
  const [categoryParentItems, setCategoryParentItems] = useState<Item[]>();
  const [usingExistingCategory, setUsingExistingCategory] = useState(false);
  const [parentCategory, setParentCategory] = useState(
    undefined as unknown as Category
  );

  const me = GroundAuthContextStore.useStoreState(
    state => state.authentication.me
  );

  const createCategoryAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.category.createCategory
  );

  const updateCategoryAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.category.updateCategory
  );

  useEffect(() => {
    const filteredParent = categories
      ?.filter(c => c.level === 0)
      ?.map(categoryY => ({
        name: getTranslation(categoryY.name),
        id: categoryY.id,
      }));
    if (filteredParent && filteredParent.length > 0) {
      setCategoryParentItems(filteredParent);
    }
  }, [categories]);

  const createUpdateCategory = (values, markForDelete = false) => {
    if (values.existingCategory) {
      toggle(
        action,
        categories?.find(categoryY => categoryY.id === values.existingCategory)
      );
    } else if (action === EnumAction.UPDATE && category) {
      setLoading(true);
      const payload: UpdateCategoryInput = {
        id: category.id,
        name: values.name,
        type: type,
        enabled,
        position: values.position,
        markForDelete,
      };

      if (values.description) payload.description = values.description;
      if (values.categoryParentId)
        payload.categoryParentId = values.categoryParentId;

      updateCategoryAction(payload)
        .then(data => {
          updateParentCategoryIfRequired(data);
          toggle(action, data);

          toast(
            intl.formatMessage({
              id: "page.category.update.category.success",
            }),
            { type: "success" }
          );
          setLoading(false);
        })
        .catch(() => {
          toggle(action);
          toast(
            intl.formatMessage({
              id: "page.category.update.category.error",
            }),
            {
              type: "error",
            }
          );
          setLoading(false);
        });
    } else if (action === EnumAction.DUPLICATE) {
      //
    } else {
      const payload: CreateCategoryInput = {
        name: values.name,
        description: values.description,
        position: values.position,
        enabled: false,
        type: type,
        markForDelete,
        level: parent ? 0 : 1,
      };
      if (values.centerSpecific) payload.categoryCenterId = centerId;
      if (values.categoryParentId)
        payload.categoryParentId = values.categoryParentId;

      setLoading(true);
      createCategoryAction(payload)
        .then(data => {
          updateParentCategoryIfRequired(data);
          toggle(action, data);

          toast(
            intl.formatMessage({
              id: "page.category.create.category.success",
            }),
            { type: "success" }
          );
          setLoading(false);
        })
        .catch(() => {
          toggle(action);
          toast(
            intl.formatMessage({
              id: "page.category.create.category.error",
            }),
            {
              type: "error",
            }
          );
          setLoading(false);
        });
    }
  };

  const updateParentCategoryIfRequired = categoryY => {
    if (categoryY?.parent && categoryY.center?.id) {
      const payload: UpdateCategoryInput = {
        id: categoryY.parent.id,
        categoryCenterId: categoryY.center?.id || null,
      };
      updateCategoryAction(payload).catch(e => console.error({ e }));
    }
  };

  const handleChange = event => {
    if (event.target.name === "existingCategory") {
      setUsingExistingCategory(!!event.target.value);
    }
    if (event.target.name === "categoryParentId" && event.target.value) {
      setParentCategory(
        categories?.find(c => c.id === event.target.value) as Category
      );
    }
  };

  const handleSubmit = values => {
    createUpdateCategory(values, false);
  };

  useEffect(() => {
    if (category) {
      setEnabled(category.enabled as boolean);
    }
  }, [category]);

  const parentInput: () => Value | null = () => {
    if (parent) return null;

    return {
      id: "categoryParentId",
      name: "categoryParentId",
      type: "select",
      label: "page.category.parent.category",
      required: false,
      disabled: usingExistingCategory,
      placeholder: "page.category.parent.category",
      items: categoryParentItems,
      useIntl: true,
      values: [
        {
          id: category?.parent?.id || parentCategory?.id || "",
          name:
            category?.parent?.name || parentCategory?.name
              ? getTranslation(category?.parent?.name || parentCategory?.name)
              : "",
        },
      ],
      children: [
        <Button
          key="categoryParentIdButton"
          id="btn-add-parent-category"
          name="btn-add-parent-category"
          item={null}
          type="button"
          outline
          onClick={() => {
            setParentAction(EnumAction.CREATE);
            setModalCategoryOpen(true);
          }}
        >
          <IntlMessages id="general.add.parent.category" />
        </Button>,
        <div key="categoryParentIdDiv" className="mt-4">
          <button
            id="btn-modify-parent-category"
            name="btn-modify-parent-category"
            data-cy="btn-modify-parent-category"
            data-testid={getCypressTestId(category)}
            type="button"
            onClick={() => {
              setParentAction(EnumAction.UPDATE);
              setModalCategoryOpen(true);
            }}
            className="inline-flex items-center border border-transparent text-12px leading-5 font-medium bg-transparent text-ground-gray-300 hover:text-ground-blue-100 focus:outline-none active:text-ground-gray-300 transition ease-in-out duration-150"
          >
            <span>
              <IntlMessages id="general.edit.parent.category" />
            </span>
          </button>
        </div>,
      ],
    };
  };

  const values: (Value | null)[] = [
    existing
      ? {
          id: "existingCategory",
          name: "existingCategory",
          type: "select",
          label: "page.category.existing",
          placeholder: "page.category.existing",
          useIntl: true,
          items: categories
            ?.filter(categoryY => categoryY.level === 1)
            .map(categoryY => ({
              name: getTranslation(categoryY.name),
              id: categoryY.id,
            })),
        }
      : null,
    {
      id: "centerSpecific",
      name: "centerSpecific",
      type: "switch",
      label: "page.category.create.center.category",
      placeholder: "page.category.create.center.category",
      required: false,
      // The category is always specific to the center if the admin is not an operator admin
      // The category is specific to the center by default
      value: canManageCategories(me)
        ? !category?.center?.id
          ? true
          : !!category?.center?.id
        : true,
      hidden: !!parent || !canManageCategories(me) || !!category,
      disabled: usingExistingCategory,
      useIntl: true,
    },
    {
      id: "name",
      name: "name",
      type: "text",
      label: "page.category.name",
      placeholder: "page.category.name",
      required: !usingExistingCategory,
      value: category?.name,
      translatable: true,
      disabled: usingExistingCategory,
      useIntl: true,
    },
    parentInput(),
    {
      id: "position",
      name: "position",
      type: "number",
      label: "general.position",
      placeholder: "general.position",
      required: !usingExistingCategory,
      value: category?.position ? category?.position : 1,
      disabled: usingExistingCategory,
      useIntl: true,
    },
    {
      id: "description",
      name: "description",
      type: "textarea",
      label: "general.description",
      placeholder: "general.description",
      required: false,
      value: category?.description,
      translatable: true,
      disabled: usingExistingCategory,
      useIntl: true,
    },
  ];

  const cleanedValues: Value[] = values.filter(el => el !== null) as Value[];

  return loading ? (
    <div className="loading" />
  ) : (
    <>
      <Header
        item={category}
        title={
          action === EnumAction.UPDATE
            ? "page.list.categories.update.category"
            : "page.list.categories.create.category"
        }
        entity={EnumPermissionEntity.CATEGORY}
        checked={enabled}
        onChange={e => {
          setEnabled(e);
        }}
        className="border-b border-gray-200"
      />
      <ModalCreateUpdateCategory
        key="parent"
        {...props}
        isOpen={modalCategoryOpen}
        parent
        toggle={() => {
          setModalCategoryOpen(!modalCategoryOpen);
        }}
        onRequestClose={() => {
          setModalCategoryOpen(!modalCategoryOpen);
        }}
        action={parentAction}
        type={type}
        centerId={centerId}
        category={
          parentAction === EnumAction.UPDATE
            ? category?.parent || parentCategory
            : null
        }
      />
      <GroundFormik
        item={category}
        values={cleanedValues}
        onSubmit={handleSubmit}
        onChange={handleChange}
      >
        <Footer
          item={category}
          index={values.length + 1}
          labels={["general.cancel", "general.delete"]}
          onCancel={() => toggle(action)}
        />
      </GroundFormik>
    </>
  );
};

export default injectIntl(CategoryForm);
